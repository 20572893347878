.MuiDialog-paper.MuiDialog-paperScrollPaper{
max-width: 100%;
}

.MuiTableCell-root.MuiTableCell-body{
    vertical-align: middle;

}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter{
    text-align: left;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter .chip-outline{
    margin: 3px;
}
 
.role-listing .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-child, .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(3){
    width: 240px;
}