.MuiTableCell-root.MuiTableCell-body {
  padding: 4px 15px !important;
  font-size: 13px;
}

.MuiButtonBase-root.MuiTableSortLabel-root {
  white-space: nowrap;
}

.MuiFormControl-root.MuiTextField-root .MuiInputBase-formControl input.MuiOutlinedInput-input {
  padding: 10.5px 14px;
}

.MuiFormControl-root.MuiTextField-root label.MuiInputLabel-outlined {
  top: -7px;
}

.MuiFormControl-root.MuiTextField-root label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 0px !important;
}

.listing-table-view {
  margin: 0px !important;
  width: 100% !important;
  padding: 0px 10px;
}

.listing-table-view #outlined-search {
  width: 300px;
  max-width: 100%;
}

.dashboard-user-grid .MuiGrid-container {
  flex-wrap: inherit !important;
}

.dashboard-user-grid .MuiGrid-container .MuiFormControl-marginNormal {
  margin-top: 0px;
  display: block;
}

.legend-status {
  padding: 17px 5px;
  display: inline-block;
}

.legend-status label {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.legend-status img {
  width: 14px;
  float: left;
  margin: 3px 5px;
}

.history-status-row {
  margin-bottom: 0px;
}

.history-status-row span {
  padding-bottom: 0px;
  padding-left: 0px;
}

.history-status-row .legend-status img {
  width: 14px;
  float: left;
  margin: 5px 5px 5px 0px;
}

.app-main-content {
  position: relative;
}

.app-main-content .loader-view {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);
}

.search-form label+.MuiInput-formControl {
  margin-top: 15px;
  width: 100%;
}

.table-rowcell-ul {
  text-decoration: none;
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.table-rowcell-ul-centered {
  text-decoration: none;
  list-style: none;
  text-align: center;
  margin: 0px;
  padding: 0 20px;
}

.table-rowcell-ul-centered li {
  display: flex;
  flex-wrap: wrap;
}

.table-rowcell-ul-centered li span.label {
  font-size: 13px;
}

.text-bold {
  font-weight: bold;
}

.tab-detail-conatiner {
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.table-rowcell-ul span,
.listing-field span {
  color: #666;
  margin-right: 0.25em;
}

.MuiTableCell-root.MuiTableCell-body {
  vertical-align: top;
}

.hide {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.ellipsis {
  width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline {
  display: inline;
}

.row-clickable-item {
  cursor: pointer;
  color: #378bfa;
  text-decoration: underline;
}

.row-clickable-doc {
  cursor: pointer;
  color: #3f51b5;
  font-size: 40px;
}

.row-clickable-item span {
  color: #378bfa;
  margin-right: 0px;
}

.doc-spinner-block {
  text-align: center;
}

.show-incomplete-checkbox {
  margin: 7px 0px;
}

.EVS-dialog {
  width: 400px;
}

.EditAccount-dialog {
  width: 1000px;
  height: 700 px;
}

.EVS-message-box {
  /* padding: 10px;
  background: #e7effb; */
  width: 100%;
  /* border: 1px solid #9aa5b7; */
  border-radius: 5px;
  color: #3f4d63;
}

.EVS-message-box span,
.EVS-error-box span {
  font-weight: 600;
  padding-right: 10px;
}

.EVS-error-box {
  padding: 10px;
  background: #fbe7e7;
  width: 100%;
  border: 1px solid #c77b7b;
  border-radius: 5px;
  color: #884343;
}

.EVS-input {
  width: 100%;
  margin-top: 10px !important;
}

th span,
td span {
  font-size: 13px !important;
}

.table-sub-fields {
  list-style: none;
  padding-left: 16px;
}

.MuiFormControl-root.datepicker-control-margin {
  margin: 15px 0px 8px 0px;
}

.MuiFormControl-root.datepicker-control-margin input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  opacity: 1;
  /* Firefox */
  font-family: "Roboto", sans-serif;
}

.MuiFormControl-root.datepicker-control-margin input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  opacity: 1;
  font-family: "Roboto", sans-serif;
}

.MuiFormControl-root.datepicker-control-margin input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  opacity: 1;
  font-family: "Roboto", sans-serif;
}

.trading-account-listing-action button {
  margin: 4px 0px;
}

/* Duplicate css */
.filter-contaner {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.filter-contaner div.MuiFormControl-root {
  flex: inherit;
  margin: 3px 3px;
  width: 165px;
}

.filter-contaner .MuiSelect-select {
  position: relative;
  top: 1px;
}

.filter-contaner .MuiInputBase-root {
  line-height: 13px;
}

.filter-contaner input.MuiInputBase-input {
  height: 30px;
}

.filter-contaner .MuiInputBase-root .MuiSelect-outlined.MuiSelect-outlined {
  padding: 15px 14px;
}

.filter-contaner .MuiFormControl-root label.MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1);
}

.filter-contaner .MuiFormControl-root label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.filter-contaner .MuiFormControl-root.MuiTextField-root .MuiInputBase-formControl input.MuiOutlinedInput-input {
  padding: 6.5px 14px;
}

.filter-contaner .MuiFormControl-root.MuiTextField-root label.MuiInputLabel-outlined {
  top: 0px;
}

.filter-contaner .MuiFormControl-root .MuiFormControl-root {
  margin: 0px;
}

.filter-contaner .MuiButton-containedPrimary {
  margin: 5px 5px 5px 0px;
}

.UploadDialog .MuiDialog-paper {
  width: 500px;
}

.MuiPaper-root .MuiGrid-spacing-xs-8 {
  width: auto;
  margin: 0px;
}

.tad-listing .MuiTableBody-root .MuiTableRow-root th.MuiTableCell-root:first-child {
  width: auto;
}

.reasonContainer div:first-child {
  padding-top: 5px;
}

.reasonContainer div:last-child {
  padding-bottom: 5px;
}

.permissionNameContainer div:first-child {
  padding-top: 5px;
}

.permissionNameContainer div:last-child {
  padding-bottom: 5px;
}