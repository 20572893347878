.timeZone-list .quick-menu.show .dropdown-menu.timezone-menu{
    width: 350px !important;
    padding: 20px;
    cursor: pointer;
}
.timeZone-list .quick-menu.show .dropdown-menu .jr-list{
    max-height: 80vh;
    overflow: auto;
}
.timeZone-list .quick-menu.show .dropdown-menu .jr-list .jr-list-item.active-timezone{
    background: #4a5bb9;
    color: #fff;
    border-radius: 3px;
}
.jr-list.jr-list-half.all-tzs .jr-list-item {
    width: 100%;
}
.search-timezone{
    width: 100%;
    margin-bottom: 10px;
}